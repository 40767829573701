import Container from '@/components/Container';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import Period from '@/page-blocks/about-us/unicef/period';
import Year from '@/page-blocks/about-us/unicef/year';
import { PageProps } from 'gatsby';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

const tabTitle = [`시대별`, `연도별`];
const tab: { [key: number]: ReactElement } = {
  0: <Period />,
  1: <Year />,
};

const PanelContainer = styled.div`
  height: 80px;
  border-bottom: 3px solid #c6c6c6;

  .UiTabPanel {
    & > li {
      width: 50%;
      text-align: center;
      margin: 0;
    }
  }
`;
const TabButton = styled.li<{ active: boolean }>`
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: block;
  font-weight: 700;
  line-height: 80px;
  font-size: 20px;
  height: 80px;
  border-bottom: 3px solid #c6c6c6;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border-color: #1cabe2;
    color: #1cabe2;
  }
`;
const TabContainer = styled.div``;
const SectionPanel = styled.section`
  background: #f8f9fa;
  padding-bottom: 310px;
  border-bottom: 1px solid #e5e6e8;

  ${breakpoint(`tablet`)} {
    padding-bottom: 150px;
  }
`;

const History: React.FC<PageProps> = ({ location }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabMenu = (id: number) => {
    setActiveTab(id);
  };

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="걸어온 길"
      description="for every child, UNICEF history"
    >
      <SectionPanel>
        <PanelContainer id="panelContainer">
          <Container>
            <ul className="UiTabPanel flex">
              {tabTitle.map((title, idx) => (
                <TabButton
                  active={activeTab === idx}
                  key={idx}
                  onClick={() => handleTabMenu(idx)}
                >
                  {title}
                </TabButton>
              ))}
            </ul>
          </Container>
        </PanelContainer>
        <TabContainer>
          <Container>{tab[activeTab]}</Container>
        </TabContainer>
      </SectionPanel>
    </LayoutWithTitle>
  );
};
export default History;
