import { HistoryControllerService } from '@/__generated__/FrontApi';
import Image from '@/components/Image';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Detail = styled.div`
  position: relative;
  &::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #1cabe2;
    border-radius: 100%;
    position: absolute;
    left: -5px;
    top: 9px;
    z-index: 1;
  }
  dt,
  dd {
    display: block;
    width: 100%;
  }

  dd {
    margin-top: 16px;
    figure {
      margin-top: 16px;
    }

    .blit-list {
      & > li {
        display: block;
        width: 100%;
        padding-left: 0;
        &::before {
          display: none;
        }

        .dec {
          display: inline-block;
          position: relative;
          padding-left: 16px;
          word-break: break-all;

          &::before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 12px;
            background: #1cabe2;
          }
        }

        ${Image} {
          margin-bottom: 32px;
        }
      }
    }
  }
`;

const HistoryConntents = styled.div`
  padding-bottom: 64px;
  text-align: center;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }
  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background: #828385;
  }
  .year {
    display: inline-block;
    padding: 0 64px;
    line-height: 54px;
    border-radius: 54px;
    background: #1cabe2;
    position: relative;
    z-index: 1;
  }
  .history-list {
    & > li {
      margin-top: 32px;
      width: 100%;
      padding-left: 50%;
      text-align: left;

      ${Detail} {
        padding-left: 38px;
      }
      &:nth-child(2n + 0) {
        padding-left: 0;
        padding-right: 50%;
        text-align: right;

        ${Detail} {
          padding-left: 0;
          padding-right: 38px;

          &::before {
            left: auto;
            right: -5px;
          }
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding-bottom: 48px;
    text-align: left;
    &::before {
      left: 51px;
      transform: translate(0, 0);
    }

    .history-list {
      & > li {
        margin-top: 24px;
        padding-left: 51px;

        ${Detail} {
          padding-left: 30px;
        }
        &:nth-child(2n + 0) {
          padding-left: 51px;
          padding-right: 0;
          text-align: left;

          ${Detail} {
            padding-left: 30px;
            padding-right: 0;

            &::before {
              left: -5px;
              right: auto;
            }
          }
        }
      }
    }
  }
`;

const KorContents = styled.div`
  & > li {
    margin-top: 32px;
    width: 100%;
    padding-left: 50%;
    text-align: left;

    ${Detail} {
      padding-left: 38px;
    }
    &:nth-child(2n + 0) {
      padding-left: 0;
      padding-right: 50%;
      text-align: right;

      ${Detail} {
        padding-left: 0;
        padding-right: 38px;

        &::before {
          left: auto;
          right: -5px;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    & > li {
      margin-top: 24px;
      padding-left: 51px;

      ${Detail} {
        padding-left: 30px;
      }
      &:nth-child(2n + 0) {
        padding-left: 51px;
        padding-right: 0;
        text-align: left;

        ${Detail} {
          padding-left: 30px;
          padding-right: 0;

          &::before {
            left: -5px;
            right: auto;
          }
        }
      }
    }
  }
`;

const GlobalContents = styled.div`
  & > li {
    margin-top: 32px;
    width: 100%;
    padding-left: 50%;
    text-align: left;

    padding-left: 0;
    padding-right: 50%;
    text-align: right;

    ${Detail} {
      padding-left: 0;
      padding-right: 38px;

      &::before {
        left: auto;
        right: -5px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    & > li {
      margin-top: 24px;
      padding-left: 51px;

      padding-left: 51px;
      padding-right: 0;
      text-align: left;

      ${Detail} {
        padding-left: 30px;
        padding-right: 0;

        &::before {
          left: -5px;
          right: auto;
        }
      }
    }
  }
`;
const History = styled.div`
  padding-top: 96px;
`;

const Year: React.FC = () => {
  const [items, setItems] = useState(``);

  const loadPage = useCallback(async () => {
    const {
      data,
      resultCode,
    } = await HistoryControllerService.getYearListUsingGet();

    if (resultCode !== `fail`) {
      setItems(data);
    }
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <>
      {items && (
        <History>
          {items.map((row, index) => (
            <HistoryConntents key={index}>
              <Tit size="s3-1" color="white" className="year">
                {row.year}
              </Tit>
              <ul className="history-list">
                {row.org.map((row2, index2) => (
                  <>
                    {row2.orgName === `유니세프한국위원회` ? (
                      <KorContents>
                        <li key={index2}>
                          <Detail>
                            <dt>
                              <Tit size="s4" color="sky">
                                {row2.orgName}
                              </Tit>
                            </dt>
                            <dd>
                              <ul className="blit-list">
                                {!!row2.board &&
                                  row2.board.map((row3, index3) => (
                                    <li key={index3}>
                                      {row3.optData3 === `Y` ? (
                                        <p className="dec">
                                          <strong>{row3.contents}</strong>
                                        </p>
                                      ) : (
                                        <p className="dec">{row3.contents}</p>
                                      )}
                                      {row3.arrAttFile.map((row4, index4) => (
                                        <figure key={index4}>
                                          <Image
                                            pcSrc={row4.imgUrl}
                                            mobileSrc={row4.imgUrl}
                                          />
                                          {row4.optData1 && (
                                            <figcaption>
                                              {row4.optData1}
                                            </figcaption>
                                          )}
                                        </figure>
                                      ))}
                                    </li>
                                  ))}
                              </ul>
                            </dd>
                          </Detail>
                        </li>
                      </KorContents>
                    ) : (
                      <GlobalContents>
                        <li key={index2}>
                          <Detail>
                            <dt>
                              <Tit size="s4" color="sky">
                                {row2.orgName}
                              </Tit>
                            </dt>
                            <dd>
                              <ul className="blit-list">
                                {!!row2.board &&
                                  row2.board.map((row3, index3) => (
                                    <li key={index3}>
                                      {row3.optData3 === `Y` ? (
                                        <p className="dec">
                                          <strong>{row3.contents}</strong>
                                        </p>
                                      ) : (
                                        <p className="dec">{row3.contents}</p>
                                      )}
                                      {row3.arrAttFile.map((row4, index4) => (
                                        <figure key={index4}>
                                          <Image
                                            pcSrc={row4.imgUrl}
                                            mobileSrc={row4.imgUrl}
                                          />
                                          {row4.optData1 && (
                                            <figcaption>
                                              {row4.optData1}
                                            </figcaption>
                                          )}
                                        </figure>
                                      ))}
                                    </li>
                                  ))}
                              </ul>
                            </dd>
                          </Detail>
                        </li>
                      </GlobalContents>
                    )}
                  </>
                ))}
              </ul>
            </HistoryConntents>
          ))}
        </History>
      )}
    </>
  );
};

export default Year;
