import { HistoryControllerService } from '@/__generated__/FrontApi';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import Image from '@/components/Image';
import Select from '@/components/Select';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { navigate } from 'gatsby';
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

const HistoryContents = styled.div`
  padding-left: 60px;
  position: relative;
  margin-bottom: 96px;
  &.historyContents {
    &::before {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #1cabe2;
    border-radius: 100%;
    position: absolute;
    left: -5px;
    top: 9px;
    z-index: 1;
  }

  .tit-wrap {
    margin-top: 16px;

    & > li {
      display: inline-block;
      vertical-align: middle;

      &::after {
        content: '|';
        display: inline-block;
        margin: 0 10px;
        font-size: 20px;
        font-weight: 700;
      }
      &:last-child::after {
        display: none;
      }

      ${Tit} {
        color: #2d2926;
        line-height: 32px;
      }
      .year {
        display: inline-block;

        padding: 0 8px;
        background: #1cabe2;
        color: #fff;
      }
    }
  }
  .dec {
    color: #2d2926;
    margin-top: 16px;
    word-break: break-all;
  }
  .img-flex {
    margin-top: 32px;
    align-items: flex-start;

    figure {
      width: 50%;
      margin-bottom: 28px;

      figcaption {
        color: #828385;
        margin-top: 8px;
        font-size: 14px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-left: 0;
    margin-top: 24px;
    &::before {
      display: none;
    }
    .tit-wrap {
      margin-top: 16px;
      & > li {
        display: block;
        width: 100%;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          display: none;
        }
        ${Tit} {
          color: #2d2926;
          line-height: 32px;
          position: relative;
          &::before {
            content: '|';
            display: inline-block;
            color: #1cabe2;
            font-weight: 700;
            margin-left: 3px;
            margin-right: 10px;
          }
        }
        .year {
          display: inline-block;
          padding: 0 8px;
          background: #1cabe2;
          color: #fff;
          &::before {
            display: none;
          }
        }
      }
    }
    .img-flex {
      margin-top: 16px;
      flex-wrap: wrap;
      figure {
        width: 100%;
      }
    }
  }
`;
const HistoryFlex = styled(AccordionItem)``;

const AccordionBody = styled.div``;

const History = styled(AccordionComponent)`
  padding-top: 96px;
  border-bottom: none;
  border-top: none;

  & > li {
    display: flex;
    align-items: stretch;
    padding-bottom: 40px;
    position: relative;
    border: 0;

    &:last-child {
      padding-bottom: 0;
    }
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 220px;
      background: #828385;
    }

    header {
      width: 220px;
      border: none;
      ${Tit} {
        line-height: 1.6;
        cursor: pointer;
        transition: color 0.15s;
        &:hover {
          color: #1cabe2;
        }
      }
      &::after {
        display: none;
      }
    }
    .accordion-content {
      width: calc(100% - 220px);
    }

    &.is-active {
      border: none;
      header {
        border: none;
        ${Tit} {
          color: #1cabe2;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 0;
  }
`;
const MobileContent = styled.div`
  padding-top: 24px;

  ul {
    border-bottom: 0 !important;
  }
`;

const Period: React.FC = () => {
  const [items, setItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState(1);

  const handleSelectedKey: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSelectedKey(Number(event.target.value));
    },
    [],
  );

  const loadPage = useCallback(async () => {
    const {
      data,
      resultCode,
    } = await HistoryControllerService.getPeriodListUsingGet();

    if (resultCode !== `fail`) {
      setItems(data);
    }
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage, selectedKey]);

  return (
    <HistoryContents className="historyContents">
      {items && (
        <>
          {/* pc */}
          <History initialKey={1} className="m-hide">
            {items.map((row) => (
              <HistoryFlex
                key={row.indexNumber}
                toggleKey={row.indexNumber}
                renderToggle={(onClick) => (
                  <Tit size="s4-1" color="gray" onClick={onClick}>
                    <button
                      type="button"
                      onClick={() =>
                        navigate(`/about-us/unicef/history/#panelContainer`)
                      }
                      css="font-weight: bold;"
                    >
                      {`${row.startPeriodDate} - ${
                        row.endPeriodDate ? `${row.endPeriodDate} 년대` : `현재`
                      }`}
                    </button>
                  </Tit>
                )}
              >
                <AccordionBody>
                  {row.board.map((row2, index2) => (
                    <HistoryContents key={index2}>
                      <Tit size="s4-1">{row2.optData2}</Tit>
                      <ul className="tit-wrap">
                        <li>
                          <Tit size="s4-1" className="year">
                            {row2.optData3}
                          </Tit>
                        </li>
                        <li>
                          <Tit size="s4-1">{row2.subject}</Tit>
                        </li>
                      </ul>
                      <p className="dec">{row2.contents}</p>
                      {row2.arrAttFile && (
                        <div className="img-flex flex">
                          {row2.arrAttFile.map((row3, index3) => (
                            <figure key={index3}>
                              <Image
                                pcSrc={row3.imgUrl}
                                mobileSrc={row3.imgUrl}
                              />
                              {row3.optData1 && (
                                <figcaption>{row3.optData1}</figcaption>
                              )}
                            </figure>
                          ))}
                        </div>
                      )}
                    </HistoryContents>
                  ))}
                </AccordionBody>
              </HistoryFlex>
            ))}
          </History>

          {/* mobile */}
          <MobileContent className="pc-hide">
            <Select onChange={handleSelectedKey}>
              {items.map((row) => (
                <option value={row.indexNumber}>
                  {`${row.startPeriodDate} - ${
                    row.endPeriodDate ? `${row.endPeriodDate} 년대` : `현재`
                  }`}
                </option>
              ))}
            </Select>
            <History initialKey={1}>
              <HistoryContents>
                <AccordionBody>
                  {items.map(
                    (row) =>
                      row.indexNumber === selectedKey &&
                      row.board.map((row2) => (
                        <HistoryContents key={row2.boardIndexNumber}>
                          <Tit size="s4-1" color="sky">
                            {row2.optData2}
                          </Tit>
                          <ul className="tit-wrap">
                            <li>
                              <Tit size="s4-1" className="year">
                                {row2.optData3}
                              </Tit>
                            </li>
                            <li>
                              <Tit size="s4-1">{row2.subject}</Tit>
                            </li>
                          </ul>
                          <p className="dec">{row2.contents}</p>
                          {row2.arrAttFile && (
                            <div className="img-flex flex">
                              {row2.arrAttFile.map((row3) => (
                                <figure key={row3.indexNumber}>
                                  <Image
                                    pcSrc={row3.imgUrl}
                                    mobileSrc={row3.imgUrl}
                                  />
                                  {row3.optData1 && (
                                    <figcaption>{row3.optData1}</figcaption>
                                  )}
                                </figure>
                              ))}
                            </div>
                          )}
                        </HistoryContents>
                      )),
                  )}
                </AccordionBody>
              </HistoryContents>
            </History>
          </MobileContent>
        </>
      )}
    </HistoryContents>
  );
};

export default Period;
